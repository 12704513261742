import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import Link from 'ui-kit/link/link';
import ErrorScreenLayout from 'components/layouts/error-screen/error-screen.layout';

const PasswordChangeLinkExpired = () => {
    const { t } = useTranslation();
    const forgotPasswordLink = (
        <Link
            to={'/forgot-password'}
            label={t(`pages.errorScreen.passwordChange.forgotPasswordLink`)}
            variant="underline-blue"
            dataGALocation="ChangePasswordLinkExpired"
            ariaLabel="Birdi Forgot Password"
        />
    );
    return (
        <ErrorScreenLayout title={t(`pages.errorScreen.passwordChange.title`)}>
            <Container>
                <Row>
                    <Col xs={12} lg={{ span: 8, offset: 2 }}>
                        {/*
                            Splitting the strings instead of sanitizing and inserting html
                            because as it contains a link, this represents a potential risk
                         */}
                        <p>
                            {t(`pages.errorScreen.passwordChange.lineOne`)}
                            {forgotPasswordLink}
                            {t(`pages.errorScreen.passwordChange.lineTwo`)}
                        </p>
                    </Col>
                </Row>
            </Container>
        </ErrorScreenLayout>
    );
};

export default PasswordChangeLinkExpired;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
